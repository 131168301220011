<template>
  <div class="config-app">
    <h2 class="app-title">Configurações de Lance</h2>
    <p class="subtitle">Permite configurar como o sistema de lances deve funcionar.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper label="Casas decimais do lance">
          <u-input class="app-input" type="number" hide-underline v-model="c('lance.casa_decimal').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Multa por desistência de arremate">
          <u-input class="app-input" hide-underline v-model="c('arremate.desistencia.multa').value" suffix="%"/>
          <p class="app-legend m-t-xs">Caso o arrematante desista de uma arrematação, o sistema financeiro gera uma multa. Deixe 0 para não aplicar multa.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Permitir lance com cadastro sem aprovação">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.lance.cadastro-nao-aprovado').value" />
          <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, o arrematante ao se cadastrar poderá efetuar um ou mais lances (de acordo o limite configurado abaixo) sem necessidade de aprovação do cadastro.</p>
        </app-label-wrapper>
        <app-label-wrapper label="Limite de lances sem aprovação">
          <u-input class="app-input" hide-underline v-model="c('arrematante.lance.cadastro-nao-aprovado-limite-lances').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Limite de incremento para lance sem aprovação">
          <u-input class="app-input" hide-underline v-model="c('arrematante.lance.cadastro-nao-aprovado-limite-incremento').value"/>
          <p class="app-legend m-t-xs">Coloque 0 para nenhum</p>
        </app-label-wrapper>

        <app-label-wrapper label="Permitir disputa de lances com parcelados com lances à vista">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.permitirDisputaParceladoXAvista').value" />
          <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, lances parcelados poderão disputar com lances à vista.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Permitir lance à vista igualar lance parcelado">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.permitirLanceAvistaIgualarParcelado').value" />
          <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, lances à vista poderão igualar lances parcelados.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Prejudicar automaticamente sublotes em lances no lote principal">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('lance.prejudicarSublotesLancePrincipal').value" />
          <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, lance no lote principal automaticamente atualiza o status dos sublotes para Prejudicado.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Modo do Lance Automático">
          <u-select :options="[{label: 'Priorizar até o valor configurado', value: '0'},{label: 'Priorizar somente até o último incremento do valor configurado', value: '1'}]" class="app-input" hide-underline v-model="c('lance.priorizarAutomatico').value" />
          <p class="app-legend m-t-xs m-b-none"><strong>Priorizar até o valor configurado</strong>, se o arrematante configurar, por exemplo, R$ 10.000,00, e o último lance for dele de R$ 9.800,00, e alguém efetuar um incremento que chegue à R$ 10.000,00, o sistema rejeita o lance do arrematante e efetua um novo lance do arrematante que configurou o lance automático com este limite.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Filtrar somente compradores no PDA">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('pda.filtrarSomenteCompradores').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <div class="m-t">
            <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
        </app-label-wrapper>
      </div>
      <div v-show="active === 'incremento'" class="app-input-container">
        <p class="font-16">Configuração de valor de incremento no cadastro</p>
        <app-label-wrapper label="Valor de Incremento padrão">
          <u-input v-if="loaded" v-money="money" class="app-input" hide-underline v-model="c('lance.incrementoPadrao').value" />
        </app-label-wrapper>
        <p class="font-16">Critérios pré-definidos de incremento</p>
        <e-row v-for="(criterio, i) in criterios" :key="'criterio' + i" mr>
          <e-col style="max-width: 200px">
            <erp-s-field
                label="Tipos de Bem:"
            >
              <erp-select multiple v-model="criterio.tiposBem" placeholder="Qualquer" :options="tiposFiltered"
                          :loading="loadingTipos" size="2">
                <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{ props.opt.label }}</span>
                </template>
              </erp-select>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Critério:"
            >
              <erp-select v-model="criterio.tipoCriterio" placeholder="Escolha" :options="[
                  {label: 'Avaliação', value: 'valorAvaliacao'},
                  {label: 'Valor Mercado/Fipe', value: 'valorMercado'},
              ]" size="2" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Valor (de):"
            >
              <erp-input v-model="criterio.valorInicial" size="2" v-money="money" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Valor (até):"
            >
              <erp-input v-model="criterio.valorFinal" size="2" v-money="money" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Incremento:"
            >
              <erp-input v-model="criterio.incremento" size="2" v-money="money" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 50px">
            <erp-s-field
                label="."
                style="display: flex; align-items: center; justify-content: center"
            >
            <div class="m-t-xs"><a @click="deleteCriterio(criterio)" class="text-negative">Remover</a></div>
            </erp-s-field>
          </e-col>
        </e-row>
        <div v-if="!criterios || !criterios.length">
          Nenhum critério de incremento configurado
        </div>
        <div class="m-t">
          <u-btn @click="addCriterio" class="app-btn" label="Configure um novo critério de incremento" no-caps color="info" />
        </div>

        <app-label-wrapper class="m-t-xxl">
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="salvar" :loading="config.loading" label="Salvar configuações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>
      </div>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import {ErpSelect, ErpSField} from "@/plugins/uloc-erp";
import ECol from "components/layout/components/Col.vue";
import {listTiposBem} from "@/domain/cadastro/services";
import {list as listSecoes} from "@/domain/bem/services/campoSecao";
import {treeCheck} from "components/cadastro/components/tiposbem/helpers/treeCheck";
import ErpInput from "@/plugins/uloc-erp/components/form/input";
import {REAL_BRL} from "@/utils/money";
import {VMoney} from "v-money";

const criterio = {
  tiposBem: [],
  tipoCriterio: 'valorAvaliacao',
  valorInicial: 0,
  valorFinal: 0,
  incremento: 0,
}

export default {
  name: "ConfigLance",
  mixins: [AppMixin],
  directives: {money: VMoney},
  components: {ErpInput, ECol, ErpSField, ErpSelect, USelect},
  data() {
    return {
      money: REAL_BRL,
      active: 'basico',
      loadingTipos: false,
      loaded: false,
      bemTipos: [],
      criterios: []
    }
  },
  watch: {
    'config.loading' (v) {
      this.testLoaded()
    }
  },
  mounted() {
    this.loadingTipos = true
    setTimeout(() => {
      this.criterios = JSON.parse(this.c('lance.criteriosIncremento').value)
      this.testLoaded()
    }, 3000)
    listTiposBem(200, 1, null, true)
        .then(({data}) => {
          this.bemTipos = data
          this.loadingTipos = false
          console.log(this.c('lance.criteriosIncremento'))
        })
        .catch(error => {
          console.error(error)
        })
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'incremento',
          label: 'Incremento'
        }
      ]
    },
    tiposFiltered() {
      if (!this.bemTipos) {
        return []
      }
      return this.treeCheck(this.bemTipos)
    }
  },
  methods: {
    testLoaded () {
      this.$nextTick(() => {
        if (!this.config.loading && !this.loaded) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.loaded = true
            }, 10)
          })
        }
      })
    },
    treeCheck,
    addCriterio () {
      this.criterios.push(JSON.parse(JSON.stringify(criterio)))
    },
    deleteCriterio (c) {
      this.criterios.splice(this.criterios.indexOf(c), 1)
    },
    salvar () {
      this.c('lance.criteriosIncremento').value = JSON.stringify(this.criterios)
      this.$nextTick(() => {
        this.$emit('salvar')
      })
    }
  }
}
</script>
