<template>
  <div class="config-app">
    <h2 class="app-title">Veículo Verificado <i class="fa fa-check-circle text-positive" /> </h2>
    <p class="subtitle">Permite integração com a Veículo Verificado.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'credenciais'" class="app-input-container">
        <div>
          A <strong>Veículo Verificado</strong> une leiloeiros para eliminar bases de dados não fidedignas, proteger o setor e restaurar a credibilidade do mercado.
          <br><br>
          <ul style="list-style-type: disc; padding: 0; margin: 0;">
            <li>- Participe dessa mudança de forma <strong>100% gratuita</strong>;</li>
            <li>- Elimine informações imprecisas que afetam sua reputação;</li>
            <li>- Fortaleça o setor com a única base oficial e confiável de veículos leiloados.</li>
          </ul>
          <br>
          Com uma base unificada, combatemos empresas que divulgam dados incorretos e protegemos os leiloeiros. Não deixe de participar dessa transformação!
          <br><br>
          Saiba mais em <a href="https://www.veiculoverificado.com.br" target="_blank">https://www.veiculoverificado.com.br</a>
        </div>
        <br>
        <br>
        <br>
        <app-label-wrapper label="Ativar integração">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('integra.veiculoVerificado').value" />
          <p class="app-legend m-t-xs">A integração consiste apenas em transmitir dados resumidos do veículo em eventos no sistema: Incluído para leilão, vendido ou retirado do leilão.</p>
        </app-label-wrapper>
      </div>
      <app-label-wrapper>
        <div class="m-t">
          <u-btn @click="$emit('salvar')" label="Salvar configurações" :loading="config.loading" no-caps color="green" class="app-btn" />
        </div>
      </app-label-wrapper>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'

export default {
  name: "IntegraVeiculoVerificado",
  mixins: [AppMixin],
  inject: ['config'],
  components: {
    // Collapse,
    USelect,
    // CodeEditor
  },
  data() {
    return {
      active: 'credenciais'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'credenciais',
          label: 'Credenciais'
        },
        // {
        //   name: 'avancado',
        //   label: 'Avançado'
        // }
      ]
    }
  }
}
</script>
